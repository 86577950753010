import PaginationData from "@/models/general/PaginationData";
import EstablishmentRole from "./EstablishmentRole";
import EstablishmentRolesFilter from "./EstablishmentRolesFilter";

export default class EstablishmentRoles {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.establishmentRolesData = [];
    this.establishmentRole = new EstablishmentRole();
    this.filterData = new EstablishmentRolesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.establishmentRolesData = data.establishmentRolesData;
  }
}
