<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <UserFormFileds :user="sellCard.userData" :submitName="submitName" />

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'cardSerialNumber'"
            :value="sellCard.cardData.cardSerialNumber"
            v-on:changeValue="sellCard.cardData.cardSerialNumber = $event"
            :title="$t('Cards.serialNumber')"
            :imgName="'serialNumber.svg'"
          />
          <CustomInputFloat
            :className="'col-md-6'"
            :id="'cardSalePrice'"
            :value="sellCard.cardSalePrice"
            v-on:changeValue="sellCard.cardSalePrice = $event"
            :title="$t('Cards.salePrice')"
            :imgName="'money.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'sellCardDescriptionAr'"
            :value="sellCard.sellCardDescriptionAr"
            v-on:changeValue="sellCard.sellCardDescriptionAr = $event"
            :title="$t('SellCards.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'sellCardDescriptionEn'"
            :value="sellCard.sellCardDescriptionEn"
            v-on:changeValue="sellCard.sellCardDescriptionEn = $event"
            :title="$t('SellCards.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'sellCardNotes'"
            :value="sellCard.sellCardNotes"
            v-on:changeValue="sellCard.sellCardNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addSellCard"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'SellCards' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../components/general/PreLoader.vue";
import UserFormFileds from "./../users/UserFormFileds.vue";
import { setDataMultiLang } from "./../../utils/functions";
import CustomInput from "./../../components/general/CustomInput.vue";
import CustomInputFloat from "./../../components/general/CustomInputFloat.vue";
import TextArea from "./../../components/general/TextArea.vue";
import createToastMixin from "./../../utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    UserFormFileds,
    CustomInput,
    CustomInputFloat,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      userTypeTokenOptions: [],
    };
  },
  props: ["sellCard", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.sellCard.mediaFile = file[0];
      }
    },
    async addSellCard() {
      this.$emit("addSellCard");
    },
    async getDialogOfUserTypes() {
      this.isLoading = true;
      this.userTypeTokenOptions = [];
      this.userTypeTokenOptions.push({
        value: "",
        text: this.$t("users.selectType"),
      });
      let genders = this.constantsListsData.listUserType;
      for (let item in genders) {
        this.userTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.imageSrc = this.sellCard.userData.defaultImg;
    this.getDialogOfUserTypes();
  },
};
</script>

<style lang="scss"></style>
