export default class SellCardsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userClientToken = "";
    this.establishmentToken = "";
    this.creationDateTime = "";
    this.lastUpdateDateTime = "";
    this.createdByUserToken = "";
    this.lastUpdatedByUserToken = "";
    this.textSearch = "";
    this.dateFrom = "";
    this.timeFrom = "";
    this.dateTo = "";
    this.timeTo = "";
    this.sendTo = "";
  }
  fillData(data) {
    this.userClientToken = data.userClientToken;
    this.establishmentToken = data.establishmentToken;
    this.creationDateTime = data.creationDateTime;
    this.lastUpdateDateTime = data.lastUpdateDateTime;
    this.createdByUserToken = data.createdByUserToken;
    this.lastUpdatedByUserToken = data.lastUpdatedByUserToken;
    this.textSearch = data.textSearch;
    this.dateFrom = data.dateFrom;
    this.timeFrom = data.timeFrom;
    this.dateTo = data.dateTo;
    this.timeTo = data.timeTo;
    this.sendTo = data.sendTo;
  }
}
