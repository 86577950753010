import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import SellCardsFilter from "./SellCardsFilter";
import Card from "./../cards/Card";
import User from "./../users/User";
import SellCardsImg from "./../../assets/images/sellCards.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "./../../utils/constants";
import i18n from "./../../i18n";
import store from "./../../store";
import { viewFileFromServer, numberToEn } from "./../../utils/functions";

export default class SellCard extends BaseEntityModel {
  defaultImg = SellCardsImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.sellCardToken = "";
    this.cardSalePrice = "";
    this.currencyNameCurrent = "";
    this.cardSalePriceView = "";
    this.sellCardDescriptionCurrent = "";
    this.sellCardDescriptionAr = "";
    this.sellCardDescriptionEn = "";
    this.sellCardDescriptionUnd = "";
    this.sellCardNotes = "";
    this.sellCardArchiveStatus = "";
    this.cardToken = "";
    this.cardData = new Card();
    this.userData = new User();
    this.mediaFile = "";
  }
  fillData(data) {
    this.fillDataBaseEntityModelBaseEntityModel(data);
    this.sellCardToken = data.sellCardToken;
    this.cardSalePrice = data.cardSalePrice;
    this.currencyNameCurrent = data.currencyNameCurrent;
    this.cardSalePriceView = data.cardSalePriceView;
    this.sellCardDescriptionCurrent = data.sellCardDescriptionCurrent;
    this.sellCardDescriptionAr = data.sellCardDescriptionAr;
    this.sellCardDescriptionEn = data.sellCardDescriptionEn;
    this.sellCardDescriptionUnd = data.sellCardDescriptionUnd;
    this.sellCardNotes = data.sellCardNotes;
    this.sellCardArchiveStatus = data.sellCardArchiveStatus;
    this.cardToken = data.cardToken;
    this.cardData.fillData(data.cardData);
  }

  async getAllSellCards(language, userAuthorizeToken, pagination, filterData) {
    return await axios.get(
      `/api/SellCards/GetAllSellCards?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&userClientToken=${filterData.userClientToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getReportSellCards(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    filterData.dateTimeFrom = filterData.dateFrom + " " + filterData.timeFrom;
    filterData.dateTimeTo = filterData.dateTo + " " + filterData.timeTo;
    try {
      let response = await axios.get(
        `/api/SellCards/GetReportSellCards?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&startActivationDateTime=${filterData.startActivationDateTime}&cardCreationPlanToken=${filterData.cardCreationPlanToken}&establishmentToken=${filterData.establishmentToken}&creationDateTime=${filterData.creationDateTime}&lastUpdateDateTime=${filterData.lastUpdateDateTime}&createdByUserToken=${filterData.createdByUserToken}&lastUpdatedByUserToken=${filterData.lastUpdatedByUserToken}&dateTimeFrom=${filterData.dateTimeFrom}&dateTimeTo=${filterData.dateTimeTo}&sendTo=${filterData.sendTo}&textSearch=${filterData.textSearch}`
      );
      if (response.data.status == STATUS.SUCCESS) {
        viewFileFromServer(response.data.reportPath);
      }
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getSellCardDialog(
    language,
    userAuthorizeToken,
    filterData = new SellCardsFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/SellCards/GetSellCardDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : SellCardsImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: SellCardsImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: SellCardsImg,
      });
    }
    return options;
  }

  async getSellCardDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/SellCards/GetSellCardDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.sellCardToken}`
    );
  }

  async addSellCard(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("fullCode", this.fullCode);
    formData.append("cardSalePrice", numberToEn(this.cardSalePrice));
    formData.append("sellCardDescriptionAr", this.sellCardDescriptionAr);
    formData.append("sellCardDescriptionEn", this.sellCardDescriptionEn);
    formData.append("sellCardDescriptionUnd", this.sellCardDescriptionUnd);
    formData.append("sellCardNotes", this.sellCardNotes);
    formData.append(
      "cardSerialNumber",
      numberToEn(this.cardData.cardSerialNumber)
    );
    formData.append("userData.fullCode", this.userData.fullCode);
    formData.append("userData.userNameAr", this.userData.userNameAr);
    formData.append("userData.userNameEn", this.userData.userNameEn);
    formData.append("userData.userNameUnd", this.userData.userNameUnd);
    formData.append("userData.userAddressAr", this.userData.userAddressAr);
    formData.append("userData.userAddressEn", this.userData.userAddressEn);
    formData.append("userData.userAddressUnd", this.userData.userAddressUnd);
    formData.append("userData.userPhone", numberToEn(this.userData.userPhone));
    formData.append(
      "userData.userPhoneCountryCode",
      this.userData.userPhoneCountryCode
    );
    formData.append(
      "userData.userPhoneCountryCodeName",
      this.userData.userPhoneCountryCodeName
    );
    formData.append("userData.userEmail", this.userData.userEmail);
    formData.append("userData.userName", this.userData.userName);
    formData.append("userData.userNationalID", this.userData.userNationalID);
    formData.append(
      "userData.userExpiryDateTimeNationalID",
      this.userData.userExpiryDateTimeNationalID
    );
    formData.append("sellWithUserTokenStatus", false);

    return await axios.post(`/api/SellCards/AddSellCard`, formData);
  }
}
