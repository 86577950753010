<template>
  <div>
    <PreLoader v-if="isLoading" />

    <div class="my-card">
      <div class="row">
        <CustomInput
          :className="'col-md-6'"
          :id="'userNameAr'"
          :value="user.userNameAr"
          :title="$t('employeeNameAr')"
          :imgName="'man.svg'"
          v-on:changeValue="user.userNameAr = $event"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="'userNameEn'"
          :value="user.userNameEn"
          :title="$t('employeeNameEn')"
          :imgName="'man.svg'"
          v-on:changeValue="user.userNameEn = $event"
        />

        <TelPicker
          :className="'col-md-6'"
          :value="user.userPhoneCCWithCCName"
          v-on:changeCC="user.userPhoneCCWithCCName = $event"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="'userPhone'"
          :value="user.userPhone"
          :title="$t('phoneNumber')"
          :imgName="'phone.svg'"
          v-on:changeValue="user.userPhone = $event"
        />

        <CustomInput
          :className="`${
            currentSubPage != 'sellCards' ? 'col-md-6' : 'col-md-12'
          }`"
          :id="'userEmail'"
          :value="user.userEmail"
          :title="$t('email')"
          :imgName="'email.svg'"
          v-on:changeValue="user.userEmail = $event"
        />
        <div
          v-if="submitName != $t('edit') && currentSubPage != 'sellCards'"
          class="form-label-group col-md-6"
        >
          <input
            name="userPassword"
            id="userPassword"
            v-bind:type="[showPassword ? 'text' : 'password']"
            class="form-control password"
            placeholder=" "
            v-model="user.userPassword"
            maxlength="50"
          />
          <label for="userPassword">{{ $t("password") }}</label>
          <img src="@/assets/images/passwordlock.svg" />
          <span class="toggle-password">
            <img
              :src="
                require('@/assets/images/eye-' +
                  [showPassword ? 'show' : 'hide'] +
                  '.svg')
              "
              @click="showPassword = !showPassword"
            />
          </span>
        </div>

        <CustomSelectBox
          v-if="currentSubPage != 'sellCards'"
          :className="'col-md-6'"
          :id="'userGenderToken'"
          :value="user.userGenderToken"
          :options="userGenderTokenOptions"
          v-on:changeValue="user.userGenderToken = $event"
          :title="$t('selectGender')"
          :imgName="'gender.svg'"
        />
        <CustomSelectBoxWithImg
          v-if="currentSubPage != 'sellCards'"
          :className="`${submitName != $t('edit') ? 'col-md-6' : 'col-md-12'}`"
          :id="'establishmentRoleToken'"
          :value="user.establishmentRoleToken"
          :options="establishmentRoleTokenOptions"
          v-on:changeValue="user.establishmentRoleToken = $event"
          :title="$t('EstablishmentRoles.select')"
          :imgName="'establishment-role.svg'"
        />

        <TextArea
          :className="'col-md-6'"
          :id="'userAddressAr'"
          :value="user.userAddressAr"
          v-on:changeValue="user.userAddressAr = $event"
          :title="$t('addressAr')"
          :imgName="'address.svg'"
        />
        <TextArea
          :className="'col-md-6'"
          :id="'userAddressEn'"
          :value="user.userAddressEn"
          v-on:changeValue="user.userAddressEn = $event"
          :title="$t('addressEn')"
          :imgName="'address.svg'"
        />

        <CustomInput
          :className="'col-md-6'"
          :id="'userNationalID'"
          :value="user.userNationalID"
          :title="$t('nationalNumber')"
          :imgName="'nationalNumber.svg'"
          v-on:changeValue="user.userNationalID = $event"
        />
        <DatePicker
          :className="'col-md-6'"
          :id="'userExpiryDateNationalID'"
          :value="user.userExpiryDateNationalID"
          v-on:changeValue="user.userExpiryDateNationalID = $event"
          :title="$t('nationalNumberExpireDate')"
          :language="language"
        />
        <CustomCheckbox
          v-if="currentSubPage != 'sellCards'"
          :className="'col-md-12'"
          :value="user.userActiveStatus"
          v-on:changeValue="user.userActiveStatus = $event"
          :title="$t('activeStatus')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import { setDataMultiLang } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TelPicker from "@/components/general/TelPicker.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import EstablishmentRoles from "./../../models/settings/establishmentRoles/EstablishmentRoles";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomInput,
    CustomSelectBoxWithImg,
    CustomSelectBox,
    CustomCheckbox,
    TelPicker,
    DatePicker,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      language: localStorage.getItem("userLanguage") || "ar",
      PageOfUsers: "",
      showPassword: false,
      imageSrc: "",
      currentSubPage: "",
      userGenderTokenOptions: [],
      establishmentRoles: new EstablishmentRoles(),
      establishmentRoleTokenOptions: [],
    };
  },
  props: ["user", "submitName"],
  methods: {
    async getDialogOfEstablishmentRoles() {
      this.isLoading = true;
      this.establishmentRoles.filterData.userTypeToken =
        this.user.userTypeToken || "";
      try {
        this.establishmentRoleTokenOptions =
          await this.establishmentRoles.establishmentRole.getDialogOfEstablishmentRoles(
            this.language,
            this.userAuthorizeToken,
            this.establishmentRoles.filterData
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfGenders() {
      this.isLoading = true;
      this.userGenderTokenOptions = [];
      this.userGenderTokenOptions.push({
        value: "",
        text: this.$t("selectGender"),
      });
      let genders = this.constantsListsData.listGender;
      for (let item in genders) {
        this.userGenderTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  watch: {
    "user.userPhoneCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.user.userPhoneCountryCode = cc[1];
      this.user.userPhoneCountryCodeName = cc[0];
    },
  },
  async created() {
    this.getDialogOfEstablishmentRoles();
    this.getDialogOfGenders();
    this.currentSubPage = this.$route.meta.currentSubPage;
  },
};
</script>

<style lang="scss"></style>
